import Vue from 'vue';
import VueRouter from 'vue-router';
// 注册路由插件
Vue.use(VueRouter)


const routes = [
    {
        path: "/",
        redirect: "/home"
    },
    {
        path: '/home',
        name: 'Home',
        component: () => import('@/views/HomePage/HomePage.vue'),
        redirect: "/home/index",
        children: [
            {
                path: '/home/index',
                name: 'index',
                component: () => import('@/components/Homecontent/index.vue'),
            },
            {
                path: '/home/jsz',
                name: 'jsz',
                component: () => import('@/components/other/index.vue'),
            },
            {
                path: '/home/xcx',
                name: 'xcx',
                component: () => import('@/components/other/index.vue'),
            },
            {
                path: '/home/lxwm',
                name: 'lxwm',
                component: () => import('@/components/other/index.vue'),
            },
            {
                path: '/home/gywm',
                name: 'gywm',
                component: () => import('@/components/other/index.vue'),
            },
        ]

    },
]

const router = new VueRouter({
    // mode: 'history',
    // base: process.env.BASE_URL,
    routes
})

// 抛出路由实例
export default router