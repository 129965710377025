<template>
    <div class="headeer">
        <div class="header">
            <div class="header-content">
                <div class="imgs"></div>
            </div>
            <div class="Navigation">
                <div class="Navigation-content">
                    <ul>
                        <li :class="$route.path == '/home/index' ? 'active' : ''">
                            <em @click="$router.push('/home/index').catch((err) => {})">首页</em >
                        </li>
                        <li :class="$route.path == '/home/jsz' ? 'active' : ''">
                            <em @click="$router.push('/home/jsz').catch((err) => {})">艾充小程序</em>
                        </li>
                         <li :class="$route.path == '/home/xcx' ? 'active' : ''">
                            <em @click="$router.push('/home/xcx').catch((err) => {})">运营方案</em >
                        </li>
                        <li :class="$route.path == '/home/lxwm' ? 'active' : ''">
                            <em @click="$router.push('/home/lxwm').catch((err) => {})">联系我们</em>
                        </li>
                         <li :class="$route.path == '/home/gywm' ? 'active' : ''">
                            <em @click="$router.push('/home/gywm').catch((err) => {})">关于我们</em >
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "Header",
    data() {
        return {
        };
    },
    methods: {

    }
};
</script>

<style lang="scss">
// .headeer {
//     position: fixed;
//     top: 0px;
//     z-index: 99;
// }
.header {
    width: 100%;
    height: 100px;
    color: #333;
    background: #fff;
    display: flex;
    justify-content: space-between;
    .header-content {
        width: 200px;
        height: 100px;
        .imgs{
            width: 104px;
            height: 60px;
            background: url('../../images/logo.png');
            margin-top: 20px;
            margin-left: 69px;
        }
    }
    .Navigation {
        // width: 950px;
        height: 100px;
        color: #000;
        .Navigation-content {
            width: 850px;
            height: 100%;
            display: flex;
            margin: auto;
            font-size: 20px;
            justify-content: space-between;
            align-items: center;
            ul {
                width: 100%;
                height: 100%;
                display: flex;
                margin-right: 150px;
                justify-content: space-evenly;
                align-items: center;

                em {
                    font-style: normal;
                    cursor: pointer;
                }
            }
        }
    }
}
.active {
    width: 120px;
    height: 30px;
    text-align: center;
    line-height: 30px;
    font-size: 20px;
    border-radius: 15px;
    background: #FDD03C;
    color: white;
}
</style>
