import Vue from 'vue'
import App from './App.vue'
//引入完整的 Element。
import ElementUI from 'element-ui';
// import "./js/index.js"
import 'element-ui/lib/theme-chalk/index.css';
Vue.use(ElementUI);

// import '@/icons' // icon

//引入路由
import router from './router'
//引入vuex
import store from './store'

// 导入公共组件并注册
import HeadPart from '@/components/Header'
import TailPart from '@/components/Tail'
Vue.component('head-part', HeadPart)
Vue.component('tail-part', TailPart)

Vue.config.productionTip = false

new Vue({
    el: '#app',
    router,//注册路由
    store,//注册vuex
    render: h => h(App)
})
