<template>
    <div id="app">
        <router-view></router-view>
    </div>
</template>

<script>
export default {
    name: "App",
    components: {},
    data(){
        return{}
    },
    mounted(){

    },
    methods:{

    }
};
</script>

<style>
body{
    width:119.625rem;
    max-width:1920px;
    min-width:320px;
    margin:0 auto;
}
* {
    margin: 0;
    padding: 0;
}

ul,
li {
    list-style: none;
}

a {
    color: #000;
    text-decoration: none;
}

#app {
    width: 100%;
    height: 100%;
    background-color: #f4f4f4;
}
::-webkit-scrollbar {
  width: 6px;

}
::-webkit-scrollbar-thumb {
  background-color: #ccc;
  border-radius: 3px;
}
.el-table__body-wrapper::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}
.el-table__body-wrapper::-webkit-scrollbar-thumb {
  background-color: #ccc;
  border-radius: 3px;
}
</style>
