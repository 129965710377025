<template>
    <div class="footer-b">
        <div class="heart">
            <div class="lxwm">联系我们</div>
            <div class="h1"><div class="icon"></div><div class="text">深圳市龙岗区龙城街道黄阁坑社区黄阁北路449号</div></div>
            <div class="h2">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;龙岗天安数码创新园3号厂房A904</div>
            <div class="h3"><div class="icon"></div><div class="text">0755-25520922 转 820</div></div>
            <div class="h4"><div class="icon"></div><div class="text">carutoo@carutoo.com</div></div>
            <div class="beian">
                <a target="_blank" href="https://beian.miit.gov.cn" style="display:inline-block;text-decoration:none;height:20px;line-height:20px;"><p style="float:left;height:20px;line-height:20px;margin: 0px 0px 0px 5px; color:#939393;">粤ICP备2022139397号</p ></a>
            </div>
            <div style="width:300px;margin:0 auto; padding:20px 0;">
                <a target="_blank" href="https://beian.miit.gov.cn" style="display:inline-block;text-decoration:none;height:20px;line-height:20px;"><img src="../../images/beian.png" style="float:left;"/><p style="float:left;height:20px;line-height:20px;margin: 0px 0px 0px 5px; color:#939393;">粤公网安备 44030702005191号</p ></a>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {};
    },
};
</script>
 
<style lang = "scss" scoped>
.footer-b {
    height: 250px;
    background: #222931;
    position: relative;
    .heart{
        height: 100%;
        color: #fff;
        font-size: 16px;
        display: flex;
        flex-direction: column;
        /* align-items: center; */
        justify-content: center;
        margin-left: 360px;
        .lxwm{
            margin-bottom: 20px;
        }
        .h1{
            display: flex;
            align-items: center;
            .icon{
                margin-right: 10px;
                width: 18px;
                height: 22px;
                background: url('../../images/dz.png') no-repeat center;
            }
        }
        .h3{
            display: flex;
            align-items: center;
            margin-bottom: 10px;
            margin-top: 10px;
            .icon{
                margin-right: 10px;
                width: 18px;
                height: 22px;
                background: url('../../images/dh.png') no-repeat center;
            }
        }
        .h4{
            display: flex;
            align-items: center;
            .icon{
                margin-right: 10px;
                width: 18px;
                height: 22px;
                background: url('../../images/yx.png') no-repeat center;
            }
        }
        .beian{
            color: rgb(147, 147, 147);
            width: 200px;
            position: absolute;
            left: 40%;
            bottom: 42px;
            /* transform: translateX(-50%); */
        }
    }
}
</style>